<template>
  <div id="pc" class="etnersWrap wrapbg">

    <recruit-main-header-area-for-login></recruit-main-header-area-for-login>
    <router-view></router-view>
    <vue-progress-bar></vue-progress-bar>
    <recruit-main-footer-area></recruit-main-footer-area>
  </div>
</template>

<script>
  /**
   * 레이아웃을 굳이 로그인용으로 하나 더 만든 이유는
   * UserMainHeaderArea.vue가 리프레시되도록 하기 위함이다.
   *
   * 로그인시 헤더의 표시값 변경을 위해 필요하다.
   *
   *
   * 만일 이것을 따로 만들지 않을 경우
   * 로그인 이벤트 동작 후 페이지 전환이 일어났을 때
   * 사용자명과 '로그아웃' 버튼으로 변경되어 표시되지 않는다.
   *
   * 왜냐하면 실제로 바뀌는 페이지는 UserLogin.vue -> UserMain.vue로의 전환일 뿐이고,
   * sessionStorage의 데이터만 추가된 것이므로,
   * UserMainHeaderArea에서는 바뀐 데이터를 감지하지 못하기 때문이다.
   *
   * 좀 더 좋은 방법이 있다면 제안바람.
   *
   * 2021. 05. 31. oxide.
   */
  import UserMainHeaderAreaForLogin from '../fragments/UserMainHeaderArea'
  import UserMainFooterArea from '../fragments/UserMainFooterArea'

  export default {
    name      : 'ForLogin',
    components: {
      UserMainHeaderAreaForLogin,
      UserMainFooterArea
    },
  }
</script>

<style>
  /* //real */
  /* @import "//design.etnersplatform.com/welcome/welcome.css"; */
  /* //test */
  /* @import "//design.etnersplatform.com/welcome/welcome.css"; */
</style>

